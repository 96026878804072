<template>
<div>
    <profile />
</div>
</template>

<script>
import profile from '../components/dashboard/Profile.vue'
export default {
    components: {
        profile
    }
}
</script>

<style>
    