<template>
    <div>
        <Wrapper>
            <Sidebar slot="sidebar" />
            <Navbar slot="navbar" />
            <Footer slot="footer" />
            <div slot="content">
                <!-- <input type="text" v-model="filters.name.value"> -->
                <div class="container-fluid">
                    <div class="pre-loader text-center" v-if="preLoader">
                        <span class="loader"></span>
                    </div>
                    <div v-if="windowWidth <= 992">
                        <div class="display-div tab-div-2 mt-4" style="padding:10px 5x">
                            <div class="row">
                                <div class="col-md-3 col-5 pb-3">
                                    <div>
                                        <img src="../../assets/images/avatar.png"
                                            style="height:100px; width:100px; border-radius:50%">
                                    </div>
                                </div>
                                <div class="col-md-6 col-7">
                                    <div
                                        style="min-height:30px; display:flex; justify-content:flex-start; align-items:flex-start">
                                        <p class="pt-2 mb-2" style="color:#000">{{userProfileInfo.first_name}}
                                            {{userProfileInfo.last_name}}</p>

                                    </div>
                                    <hr style="margin-top: 0.3rem !important;
        margin-bottom: 0.3rem !important;">
                                    <div
                                        style="min-height:30px; display:flex; justify-content:flex-start; align-items:flex-start">
                                        <div>
                                            <span class="pb-2" style="color:#828282">
                                                Referral Code
                                            </span>
                                            <div style="min-height:40px">
                                                <p style="color: #52DD8F" class="pt-1">{{userProfileInfo.ref_code}}<img
                                                        src="../../assets/images/copyRef.png" height="30"
                                                        style="opacity:0" alt=""></p>

                                            </div>
                                        </div>

                                    </div>
                                    <hr style="margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;">

                                </div>
                                <div class="col-md-12 col-12">
                                    <div
                                        style="min-height:30px; display:flex; justify-content:flex-start; align-items:flex-start">
                                        <div>
                                            <span class="pb-2" style="color:#828282">
                                                Referral Link
                                            </span>
                                            <div @click="copyRefCode($event)" :content="tran.ref_to_clipboard"
                                                v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}">
                                                <div style="opacity:0; width:1px;height:1px">
                                                    <input class="ref-url" type="hidden"
                                                        :value="`https://requid.me/${userProfileInfo.ref_code}`">
                                                </div>
                                                <div class="point-cursor "
                                                    style="display:flex; min-height:20px; align-items:center;">
                                                    <div>
                                                        <span class="point-cursor"
                                                            style="z-index:20; color: #52DD8F">{{`requid.me/${userProfileInfo.ref_code}` }}</span>
                                                    </div>
                                                    <div class="text-right">
                                                        <img src="../../assets/images/copyRef.png" height="30" alt="">
                                                    </div>


                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <hr style="margin-top: 0.3rem !important;
        margin-bottom: 0.3rem !important;">



                                </div>
                                <div class="col-md-12 col-12 mt-2">
                                    <div
                                        style="min-height:30px; display:flex; justify-content:flex-start; align-items:flex-start">
                                        <div>

                                            <div style="display:flex; min-height:20px; align-items:center">
                                                <div>
                                                    <span style="color:#4F4F4F" data-toggle="modal"
                                                        data-target="#referralModal">See Referrals</span>
                                                </div>
                                                <div class="text-right">
                                                    <p data-toggle="modal" data-target="#referralModal"
                                                        class=" point-cursor"><img
                                                            src="../../assets/images/speaker2.png" height="30" alt="">
                                                    </p>
                                                </div>


                                            </div>
                                        </div>
                                    </div>





                                </div>

                            </div>
                        </div>


                        <div class="display-div tab-div-2 mt-4" style="padding:10px 5px">
                            <div class="row">
                                <div class="col-md-12 col-12 pb-2">
                                    <img src="../../assets/images/prof_mob.png" height="30" class="mr-4" alt="">
                                    <span @click="gotoPersonalProfile">Profile</span>

                                </div>
                                <div class="col-md-12 col-12 pb-2">
                                    <hr style="margin-top: 0.3rem !important;
        margin-bottom: 0.3rem !important;">
                                </div>
                                <div class="col-md-12 col-12 pb-2">
                                    <img src="../../assets/images/payout_mob.png" height="30" class="mr-4" alt="">
                                    <span @click="gotoSecurityProfile"> Payout & Security</span>
                                </div>

                            </div>
                        </div>

                    </div>


                    <section id="tabs" class="project-tab" v-if="windowWidth > 992">
                        <div class="container-fluid">
                            <nav>
                                <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist" style="    max-width: 73%;
">
                                    <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab"
                                        href="#nav-home" role="tab" aria-controls="nav-home"
                                        aria-selected="true">{{tran.basic_info}}</a>
                                    <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab"
                                        href="#nav-profile" role="tab" aria-controls="nav-profile"
                                        aria-selected="false">{{tran.profile}}</a>

                                    <a class="nav-item nav-link" id="nav-security-tab" data-toggle="tab"
                                        href="#nav-security" role="tab" aria-controls="nav-security"
                                        aria-selected="false">{{tran.payout_security}}</a>
                                </div>
                            </nav>
                            <div class="tab-content" id="nav-tabContent">
                                <div class="tab-pane fade show active" id="nav-home" role="tabpanel"
                                    aria-labelledby="nav-home-tab">

                                    <div class="display-div tab-div-2">
                                        <div class="row" style="border-bottom:1px solid #E0E0E0">
                                            <div class="col-md-5 col-12 pb-3">
                                                <img src="../../assets/images/get1.png" height="20" class="pr-4">
                                                <img src="../../assets/images/getbox.png" height="30">
                                                <p class="pt-3" style="color:#828282">Get 1% on every first investment
                                                    by sharing your username and referral link.</p>
                                                <p style="color:#828282" class="pt-3">Participate in promotions, recieve
                                                    funds and investments using this code</p>
                                            </div>
                                            <div class="col-md-7 col-12">

                                                <div class="display-div">

                                                    <div>
                                                        <span class="pb-2" style="color:#828282">
                                                            Referral Code
                                                        </span>
                                                        <div style="min-height:40px">
                                                            <p style="color: #52DD8F" class="pt-1">
                                                                {{userProfileInfo.ref_code}}<img
                                                                    src="../../assets/images/copyRef.png" height="30"
                                                                    style="opacity:0" alt=""></p>

                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span class="pb-2" style="color:#828282">
                                                            Referral Link
                                                        </span>
                                                        <div @click="copyRefCode($event)"
                                                            :content="tran.ref_to_clipboard"
                                                            v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}">
                                                            <div style="opacity:0; width:1px;height:1px">
                                                                <input class="ref-url" type="hidden"
                                                                    :value="`https://requid.me/${userProfileInfo.ref_code}`">
                                                            </div>
                                                            <div class="point-cursor">
                                                                <p class="point-cursor"
                                                                    style="z-index:20; color: #52DD8F">
                                                                    {{`requid.me/${userProfileInfo.ref_code}` }}<img
                                                                        src="../../assets/images/copyRef.png"
                                                                        height="30" alt=""></p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr>
                                                <div class="display-div mob-div pt-3" style="flex-wrap:wrap;">
                                                    <p style="display:flex;  align-items:center">


                                                        <social-sharing
                                                            :url="`https://requid.me/${userProfileInfo.ref_code}`"
                                                            description="I invest in safe investment options on reQuid. You can also start investing with as low as N5,000 and also enjoy the benefit of liquidating (sell) your investment. Start investing today by clicking on the link below to sign up"
                                                            title="I invest in safe investment options on reQuid. You can also start investing with as low as N5,000 and also enjoy the benefit of liquidating (sell) your investment. Start investing today by clicking on the link below to sign up"
                                                            quote="I invest in safe investment options on reQuid. You can also start investing with as low as N5,000 and also enjoy the benefit of liquidating (sell) your investment. Start investing today by clicking on the link below to sign up"
                                                            hashtag="#requid, #invest" twitter-user="requidapp"
                                                            inline-template>

                                                            <div>
                                                                <network network="twitter">


                                                                    <img src="../../assets/images/twit.png" height="30"
                                                                        title="share via twitter" class="pr-3"
                                                                        v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'
                                                                        alt="">
                                                                </network>


                                                                <network network="facebook">
                                                                    <img src="../../assets/images/face2.png"
                                                                        class="pr-3" height="30"
                                                                        title="share via facebook"
                                                                        v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'
                                                                        alt="">

                                                                </network>

                                                                <network network="whatsapp">
                                                                    <img src="../../assets/images/whats.png" height="30"
                                                                        title="share via WhatsApp"
                                                                        v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'
                                                                        alt="">
                                                                </network>

                                                            </div>
                                                        </social-sharing>
                                                    </p>
                                                    <div>
                                                        <template v-if="this.userProfileInfo.referral !== undefined">
                                                            <p data-toggle="modal" data-target="#referralModal"
                                                                class=" point-cursor">See Referrals <img
                                                                    src="../../assets/images/speaker2.png" height="30"
                                                                    alt=""></p>
                                                        </template>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="display-div tab-div-2" style=" margin-top:-20px">
                                        <div class="row">

                                            <div class="col-md-5 col-12 pb-3">
                                                <div>

                                                    <img src="../../assets/images/group.png" height="120">
                                                </div>
                                                <img src="../../assets/images/weare.png" height="40" class="mt-3">
                                            </div>
                                            <div class="col-md-7 col-12">
                                                <div v-show="fillTestimony">
                                                    <h4 class="head-font mb-2">{{tran.rate_us}}</h4>

                                                    <p class="">{{tran.let_us_know}}</p>

                                                    <div class="pt-4"
                                                        style="display:flex; justify-content:center; flex-direction:column">

                                                        <center>
                                                            <div style="width:40%; border-top: 1px solid #FDC23C;">
                                                            </div>
                                                        </center>
                                                        <!-- <p class="pt-2"><b>{{tran.rate_your_expe}}</b></p> -->
                                                    </div>

                                                    <div class=" rate-div">
                                                        <fieldset class="rating">
                                                            <input type="radio" id="star51" name="rating" value="5"
                                                                :checked="getStarRate() == 5" />
                                                            <label class="full point-cursor" for="star51"
                                                                title="Awesome - 5 stars"
                                                                @click="highStarMethod(5)"></label>

                                                            <input type="radio" id="star41" name="rating" value="4"
                                                                :checked="getStarRate() == 4" />
                                                            <label class="full point-cursor" for="star41"
                                                                @click="highStarMethod(4)"></label>

                                                            <input type="radio" id="star31" name="rating" value="3"
                                                                :checked="getStarRate() == 3" /><label
                                                                class="full point-cursor" for="star31"
                                                                @click="highStarMethod(3)"></label>

                                                            <input type="radio" id="star21" name="rating" value="2"
                                                                :checked="getStarRate() == 2" />
                                                            <label class="full point-cursor" for="star21"
                                                                @click="lowStarMethod(2)"></label>

                                                            <input type="radio" id="star11" name="rating" value="1"
                                                                :checked="getStarRate() == 1" /><label
                                                                class="full point-cursor" for="star11"
                                                                @click="lowStarMethod(1)"></label>
                                                        </fieldset>
                                                    </div>

                                                    <div class="text-center pt-1"
                                                        style="display:flex; justify-content:center; flex-direction:column">

                                                        <center>
                                                            <div style="width:60%; border-top: 1px solid #FDC23C;">
                                                            </div>
                                                            <div style="width:20%; border-top: 1px solid #FDC23C;"
                                                                class="mt-3"></div>
                                                        </center>

                                                    </div>

                                                    <div class="mt-3 mb-3">
                                                        <p class="text-center animated fadeIn" v-if="highStar">
                                                            <b>Nice!</b> {{tran.nice_do_you}} </p>

                                                        <p class="text-center animated fadeIn" v-if="lowStar">
                                                            <b>Ouch!</b> {{tran.ouch_please}} </p>
                                                    </div>

                                                    <div class="mt-1">
                                                        <textarea name="" id="" cols="30"
                                                            v-model="testimonialDataFromStore.testimony" rows="4"
                                                            style="width:100%; border-radius:5px !important"
                                                            minlength="3" maxlength="250"
                                                            placeholder="Type here"></textarea>
                                                    </div>
                                                    <!-- <div class="com-pub mt-2">
                                  <div class="custom-control text-center custom-checkbox my-1 mt-4 mr-sm-2">
                                                                    <input type="checkbox" class="custom-control-input"  v-model ="testimonialData.share" id="customControlInline" >
                                                                    <label class="custom-control-label" title="Make my comments public" for="customControlInline"><b>Make my comments public</b></label>

                                                                </div>

                                               <p class="text-center pt-2">Your testimonial helps inform others to invest healthy.</p>
                                     </div> -->

                                                    <!-- <p class="drift-open-chat text-center pt-3" style="text-decoration:underline; cursor:pointer"> {{ tran.contact_sup }} </p> -->
                                                </div>

                                                <!-- <div @click="submitTestimonial"
                                                    :class="{'fade-elem':testimonialDataFromStore.rating == '' || testimonialDataFromStore.testimony == '' }">
                                                    <p class="point-cursor">{{ loader ? '' : 'Post Comment'}}
                                                        <span class="spinner-border spinner-border-sm" role="status"
                                                            aria-hidden="true" v-if="loader"></span>
                                                    </p>
                                                </div> -->
                                                <div @click="submitTestimonial"
                                                   >
                                                    <p class="point-cursor">{{ loader ? '' : 'Post Comment'}}
                                                        <span class="spinner-border spinner-border-sm" role="status"
                                                            aria-hidden="true" v-if="loader"></span>
                                                    </p>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <!-- <div class="row">
                    <div class="col-md-12 text-center">
                      <button class="vest-btn-2 mt-3"  @click="gotoVestlock">
                        <b> Goto Vestlock </b>
                      </button>
                    </div>
                  </div> -->
                                </div>


                                <!-- PROFILE NAV -->
                                <div class="tab-pane fade" id="nav-profile" role="tabpanel"
                                    aria-labelledby="nav-profile-tab">

                                    <div class="display-div tab-div-2">
                                        <div class="row" style="border-bottom:1px solid #E0E0E0">
                                            <div class="col-md-4 col-12 pb-3">
                                                <div>
                                                    <img src="../../assets/images/avatar.png"
                                                        style="height:100px; width:100px; border-radius:50%">
                                                </div>
                                            </div>
                                            <div class="col-md-5 col-12">
                                                <div style="min-height:30px; display:flex; align-items:center">

                                                </div>
                                                <p class="pt-2 mb-2" style="color:#000">{{userProfileInfo.email}}</p>


                                            </div>

                                        </div>
                                    </div>
                                    <div class="display-div tab-div-2" style=" margin-top:-20px">
                                        <div class="row">

                                            <div class="col-md-4 col-12 pb-3">
                                                <div>

                                                    <p style="color:#52DD8F">Username</p>
                                                </div>
                                                <p style="color:#828282">Participate in promotions, receive funds and
                                                    investments using this code</p>
                                            </div>
                                            <div class="col-md-7 col-12">

                                                <div class="display-div">

                                                    <div>
                                                        <span class="pb-2" style="color:#828282">
                                                            Referral Code
                                                        </span>
                                                        <div style="min-height:40px">
                                                            <p style="color: #52DD8F" class="pt-1">
                                                                {{userProfileInfo.ref_code}}<img
                                                                    src="../../assets/images/copyRef.png" height="30"
                                                                    style="opacity:0" alt=""></p>

                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span class="pb-2" style="color:#828282">
                                                            Referral Link
                                                        </span>
                                                        <div @click="copyRefCode($event)"
                                                            :content="tran.ref_to_clipboard"
                                                            v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}">
                                                            <div style="opacity:0; width:1px;height:1px">
                                                                <input class="ref-url" type="hidden"
                                                                    :value="`https://requid.me/${userProfileInfo.ref_code}`">
                                                            </div>
                                                            <div class="point-cursor">
                                                                <p class="point-cursor"
                                                                    style="z-index:20; color: #52DD8F">
                                                                    {{`requid.me/${userProfileInfo.ref_code}` }}<img
                                                                        src="../../assets/images/copyRef.png"
                                                                        height="30" alt=""></p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>


                                        </div>
                                    </div>
                                    <div class="display-div tab-div-2 mt-4">
                                        <div class="row" style="border-bottom:1px solid #E0E0E0">
                                            <div class="col-md-4 col-12 pb-3">
                                                <div>
                                                    <h5 class="fw-600">Full Name</h5>
                                                    <p style="color:#828282">Your name on the platform</p>
                                                </div>
                                            </div>
                                            <div class="col-md-8 col-12">
                                                <div style="display:flex;">
                                                    <div style="flex-basis:0%" class="mr-3">
                                                        <p style="color:#828282">First Name</p>
                                                        <input v-model="userProfileInfo.first_name" type="text"
                                                            autocomplete="off"
                                                            :class="{'disable-input':!editProfile , 'enable-input':editProfile }"
                                                            placeholder="Surname" v-focus>
                                                    </div>

                                                    <div style="flex-basis:20%">
                                                        <p style="color:#828282">Last Name</p>
                                                        <input v-model="userProfileInfo.last_name" type="text"
                                                            :class="{'disable-input':!editProfile, 'enable-input':editProfile}"
                                                            autocomplete="off" placeholder="First name">

                                                    </div>
                                                </div>
                                                <div class="pb-3">
                                                </div>
                                                <hr
                                                    v-if="!editProfile && userProfileInfo.bvn == null || !editProfile && userProfileInfo.bvn == '0'">
                                                <hr v-if="editProfile">
                                                <div class="pb-3 pt-2"
                                                    v-if="!editProfile && userProfileInfo.bvn == null ||!editProfile && userProfileInfo.bvn == '0'"
                                                    @click.stop="editUserProfile">

                                                    <button class="text-left"
                                                        style="background:#fff; border:1px solid #fff; font-weight:300; padding:0; font-size: 14px; min-height:40px; min-width:150px; color:#0094FF;border-radius:3px; min-width:100px">



                                                        Edit details



                                                    </button>
                                                </div>
                                                <!-- <p style="color:#0094FF;" class="pt-3 pb-4 point-cursor">Edit details</p> -->

                                                <div class="pb-3 pt-2" v-if="editProfile">

                                                    <button class="mr-4 "
                                                        style="background:transparent; border:1px solid transparent;min-width:150px; color:#4F4F4F"
                                                        @click="stopEditUserProfile">
                                                        <p>Cancel</p>
                                                    </button>
                                                    <button
                                                        style="background:#0094FF; border:1px solid #0094FF; font-weight:300; min-height:40px; min-width:150px; color:#fff;border-radius:3px; min-width:100px"
                                                        @click="updateUserProfile">


                                                        <p> {{editProfileLoader ? '' : 'Save Changes'}}</p>

                                                        <span class="spinner-border spinner-border-sm" role="status"
                                                            aria-hidden="true" v-if="editProfileLoader">
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="display-div tab-div-2" style=" margin-top:-20px">
                                        <div class="row">

                                            <div class="col-md-4 col-12 pb-3">
                                                <div>

                                                    <h5 class="fw-600">Phone Number</h5>
                                                </div>

                                            </div>
                                            <div class="col-md-6 col-12">

                                                <div style="display:flex;">
                                                    <div style="flex-basis:0%">
                                                        <p class="" style="color:#000">
                                                            {{userProfileInfo.phone | spaceOutNumbers}}</p>
                                                    </div>

                                                    <div style="flex-basis:20%">
                                                        <p style="background:transparent; color:#0094FF; border-radius:3px; border:1px solid transparent;font-size:12px; min-width:70px"
                                                            class="text-left point-cursor"
                                                            @click="userClicksOnChangePhone">

                                                            Change

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                    <div class="display-div tab-div-2 mt-4">
                                        <div class="row pb-3" style="border-bottom:1px solid #E0E0E0">
                                            <div class="col-md-4 col-12 pb-3">
                                                <div>
                                                    <h5 class="fw-600">Next of Kin</h5>
                                                    <p style="color:#828282">Details of your successor</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12">
                                                <div style="display:flex;">
                                                    <div>
                                                        <p style="color:#828282">Full Name</p>
                                                        <input v-model="userProfileInfo.next_kin_name" type="text"
                                                            autocomplete="off"
                                                            :class="{'disable-input':!editNextOfKin , 'enable-input':editNextOfKin }"
                                                            placeholder="Full name"
                                                            style="min-width:100%; max-width:100%" autofocus>
                                                    </div>


                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="display-div tab-div-2" style=" margin-top:-20px">
                                        <div class="row" style="border-bottom:1px solid #E0E0E0">

                                            <div class="col-md-4 col-12 pb-3">
                                                <div>

                                                    <h5 class="fw-600">Relationship</h5>
                                                    <p style="color:#828282">Mother, Brother, Sister</p>
                                                </div>

                                            </div>
                                            <div class="col-md-6 col-12">

                                                <div style="display:flex;">
                                                    <input v-model="userProfileInfo.next_kin_relationship" type="text"
                                                        autocomplete="off"
                                                        :class="{'disable-input':!editNextOfKin , 'enable-input':editNextOfKin }"
                                                        placeholder="Relationship"
                                                        style="min-width:100%; max-width:100%">

                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                    <div class="display-div tab-div-2" style=" margin-top:-20px">
                                        <div class="row pb-3" style="border-bottom:1px solid #E0E0E0">

                                            <div class="col-md-4 col-12 pb-3">
                                                <div>

                                                    <h5 class="fw-600">Email Address</h5>

                                                </div>

                                            </div>
                                            <div class="col-md-6 col-12">

                                                <div style="display:flex;">
                                                    <input v-model="userProfileInfo.next_kin_email" type="text"
                                                        autocomplete="off"
                                                        :class="{'disable-input':!editNextOfKin , 'enable-input':editNextOfKin }"
                                                        placeholder="Email" style="min-width:100%; max-width:100%">

                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                    <div class="display-div tab-div-2" style=" margin-top:-20px">
                                        <div class="row pb-3" style="border-bottom:1px solid #E0E0E0">

                                            <div class="col-md-4 col-12 pb-3">
                                                <div>

                                                    <h5 class="fw-600">Phone Number</h5>

                                                </div>

                                            </div>
                                            <div class="col-md-6 col-12">

                                                <div style="display:flex;">
                                                    <input v-model="userProfileInfo.next_kin_phone" type="text"
                                                        autocomplete="off"
                                                        :class="{'disable-input':!editNextOfKin , 'enable-input':editNextOfKin }"
                                                        placeholder="Phone Number"
                                                        style="min-width:100%; max-width:100%">

                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                    <div class="display-div tab-div-2"
                                        style="align-items:center; min-height:56px; margin-top:-20px;">
                                        <div class="row">

                                            <div class="col-md-4 col-12">


                                            </div>
                                            <div class="col-md-6 col-12">

                                                <div class="">
                                                    <button class="text-left"
                                                        style="background:#fff; border:1px solid #fff; font-weight:300; padding:0; font-size: 14px; min-height:40px; min-width:150px; color:#0094FF;border-radius:3px; min-width:100px"
                                                        v-if="!editNextOfKin" @click.stop="editNextOfKinMethod">


                                                        Edit details



                                                    </button>


                                                    <button class="mr-4"
                                                        style="background:transparent; border:1px solid transparent;min-width:150px;    font-size: 14px; color:#4F4F4F"
                                                        v-if="editNextOfKin" @click="stopEditNextOfKin">
                                                        Cancel
                                                    </button>
                                                    <button
                                                        style="background:#0094FF; border:1px solid #0094FF; font-weight:300;     font-size: 14px; min-height:40px; min-width:150px; color:#fff;border-radius:3px; min-width:100px"
                                                        v-if="editNextOfKin" @click="updateNextOfKin">


                                                        {{nextOfKinLoader ? '' : 'Save Changes'}}

                                                        <span class="spinner-border spinner-border-sm" role="status"
                                                            aria-hidden="true" v-if="nextOfKinLoader">
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                </div>

                                <!-- SECURITY TAB -->
                                <div class="tab-pane fade" id="nav-security" role="tabpanel"
                                    aria-labelledby="nav-security-tab">


                                    <div class="display-div tab-div-2">
                                        <div class="row pb-3" style="border-bottom:1px solid #E0E0E0">
                                            <div class="col-md-4 col-12">
                                                <div>
                                                    <h5 class="fw-600">Payout Details</h5>
                                                </div>
                                            </div>
                                            <div class="col-md-7 col-12">
                                                <div style="display:flex;">
                                                    <div style="flex-basis:50%" class="mr-3">
                                                        <p style="color:#828282">Bank Name</p>
                                                        <select @change="getBankSelected($event)"
                                                            :class="{'disable-input':!editBankDetail, 'enable-input':editBankDetail}"
                                                            class="text-center">
                                                            <option v-for="bank in bankNames" :value="bank.id"
                                                                :key="bank.id"
                                                                :selected="bank.name == userProfileInfo.bank_name">
                                                                {{bank.name}}</option>
                                                        </select>
                                                    </div>

                                                    <div style="flex-basis:50%">
                                                        <p style="color:#828282">Account Number</p>
                                                        <input type="text" autocomplete="off"
                                                            :class="{'disable-input':!editBankDetail, 'enable-input':editBankDetail}"
                                                            v-model="userProfileInfo.bank_account_no" maxlength="10"
                                                            @input="getBankNames" style="min-width:100%">

                                                    </div>
                                                </div>
                                                <div style="display:flex;">
                                                    <div style="flex-basis:20%">
                                                        <div class="mt-3"
                                                            v-if="userProfileInfo.bvn == null || userProfileInfo.bvn == '0'">
                                                            <p style="color:#828282">BVN <span
                                                                    style="color:#000; pointer-events:all"> <i
                                                                        class="fa fa-question-circle"
                                                                        title="we need your bvn to validate your bank account details, we don't store your bvn"
                                                                        v-tippy='{ arrow : true, theme:"light", placement:"bottom", arrowType : "round"}'></i></span>
                                                            </p>

                                                            <div>

                                                                <input v-model="bvn"
                                                                    :class="{'disable-input':!editBankDetail, 'enable-input':editBankDetail}"
                                                                    type="number" autocomplete="off" maxlength="11"
                                                                    style="min-width:100%">
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div v-if="userProfileInfo.bank_account_name !== null"
                                                    class="animated fadeIn">

                                                    <p class="mt-3" style="color:#828282">{{tran.acc_name}}</p>
                                                    <p style="font-size:14px; font-weight:600"
                                                        v-if="userProfileInfo.bank_account_name !== null">
                                                        {{userProfileInfo.bank_account_name}}</p>

                                                </div>
                                                <div>

                                                    <span class="spinner-border spinner-border-sm" role="status"
                                                        aria-hidden="true" v-if="searchingAccountName"></span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="display-div tab-div-2"
                                        style="align-items:center; min-height:56px; margin-top:-20px;">
                                        <div class="row">

                                            <div class="col-md-4 col-12">


                                            </div>
                                            <div class="col-md-6 col-12">

                                                <div class="">
                                                    <button class="text-left"
                                                        style="background:#fff; border:1px solid #fff; font-weight:300; padding:0; font-size: 14px; min-height:40px; min-width:150px; color:#0094FF;border-radius:3px; min-width:100px"
                                                        v-if="!editBankDetail" @click.stop="editUserBankDetail">


                                                        <p> Edit details </p>



                                                    </button>

                                                </div>

                                                <div v-if="editBankDetail">
                                                    <button class="mr-4"
                                                        style="background:transparent; border:1px solid transparent;min-width:150px;    font-size: 14px; color:#4F4F4F"
                                                        @click="stopEditUserBankDetail">
                                                        <p> Cancel </p>
                                                    </button>
                                                    <button
                                                        style="background:#0094FF; border:1px solid #0094FF; font-weight:300;     font-size: 14px; min-height:40px; min-width:150px; color:#fff;border-radius:3px; min-width:100px"
                                                        @click="updateBankDetails">
                                                        <p> {{editBankDetailLoader ? '' : 'Save Changes'}} </p>



                                                        <span class="spinner-border spinner-border-sm" role="status"
                                                            aria-hidden="true" v-if="editBankDetailLoader">
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>




                                        </div>
                                    </div>

                                    <div style="padding:10px 20px 5px 20px">
                                        <div class="row">
                                            <div class="col-md-4 col-12">
                                                <h5 style="color:#828282">Security</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="display-div tab-div-2">
                                        <div class="row pb-3" style="border-bottom:1px solid #E0E0E0">
                                            <div class="col-md-4 col-12 pb-3">
                                                <div>
                                                    <h5 class="fw-600 ">PIN</h5>
                                                </div>
                                            </div>
                                            <div class="col-md-8 col-12">
                                                <div style="display:flex;">
                                                    <div style="flex-basis:60%; ">
<div style=" display:flex">
                                                        <p style="color:#0094FF" class="pb-2 point-cursor"
                                                            v-if="userProfileInfo.has_pin" @click="openChangePinModal">
                                                            Change PIN</p>
                                                        <p style="color:#0094FF" class="pb-2 point-cursor"
                                                            v-if="userProfileInfo.has_pin" @click="openforgotPinModal">
                                                            Forgot PIN?</p>
</div>
                                                        <p style="color:#0094FF" class="pb-2 point-cursor"
                                                            v-if="userProfileInfo.has_pin == false" data-toggle="modal"
                                                            data-target="#createPinModal">Create PIN</p>
                                                        <p style="color:#828282;"> Choose something more memorable</p>
                                                    </div>


                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="display-div tab-div-2" style=" margin-top:-20px">
                                        <div class="row">

                                            <div class="col-md-4 col-12 pb-3">
                                                <div>

                                                    <h5 class="fw-600">Password</h5>
                                                </div>

                                            </div>
                                            <div class="col-md-6 col-12">

                                                <div style="display:flex;">
                                                    <div style="flex-basis:60%">
                                                        <p style="color:#0094FF" class="pb-2 point-cursor"
                                                            @click="openChangePasswordModal">Change Password</p>
                                                        <p style="color:#828282;"> Choose this option to have your
                                                            password updated to something better</p>
                                                    </div>

                                                </div>
                                            </div>




                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </section>









                    <template v-if="searchString == ''">
                        <div>
                            <div class="">




                                <!-- 
                                                                         <div class="prof-cover mt-2">
                                                                             <h5 class="text-center fw-600 mt-3">{{tran.secret_ques}} </h5>

                                                                         <div class="pl-4 mt-2 mr-4">
                                                                           <p class="mb-2">{{tran.secret_ques_1}}</p>
                                                     <select @change="getBankSelected($event)">
                                                           <option v-for="bank in bankNames" :value="bank.id" :key="bank.id" :selected="bank.name == userProfileInfo.bank_name">
                                                              {{bank.name}}</option>
                                                        </select>
                                                      </div>
                                                        <div class="pl-4 mt-3 mr-4">
                                                            <p>{{tran.answer}}</p>
                                                            <input class="form-input" type="text" v-model="nextOfKin.nextk_relationship" autocomplete="off">
                                                   </div>

                                                            <div class="pl-4 mt-2 mr-4">

                                                        <p class="mb-2 mt-4">{{tran.secret_ques_2}}</p>
                                                     <select @change="getBankSelected($event)">
                                            <option v-for="bank in bankNames" :value="bank.id" :key="bank.id" :selected="bank.name == userProfileInfo.bank_name">
                                                {{bank.name}}</option>
                                           </select>
                                               </div>
                                                        <div class="pl-4 mt-3 mr-4">
                                                            <p>{{tran.answer}}</p>
                                                            <input class="form-input" type="text" v-model="nextOfKin.nextk_relationship" autocomplete="off">
                                                   </div>

                                                                    <div class="display-div">
                                                                        <div style="background: #f1faf0 !important; height:50px;border-radius:3px;color:#58B46F" class="position-el  mt-4" v-if="editNextOfKin" @click="stopEditUserBankDetail">
                                                                            <p class="text-center"><b>{{tran.cancel}}</b></p>
                                                                        </div>

                                                                        <div style="background: #58B46F !important; height:50px; color:#fff; border-radius:3px" class="position-el mt-4" v-if="editNextOfKin" @click="updateNextOfKin">

                                                                            <p class="text-center" v-if="!nextOfKinLoader"><b>{{nextOfKinLoader ? '' : tran.save}}</b></p>
                                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="nextOfKinLoader" >
                                                             </span>
                                                                        </div>
                                                                    </div>

                                                                    <div class="btn-cover-2">
                                                                        <div style="background: #f1faf0 !important; height:50px" class="position-el mt-4" v-if="!editNextOfKin" @click="editNextOfKinMethod">
                                                                            <p class="text-center"><b>{{tran.edit}}</b></p>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div class="prof-cover mt-2" style="min-height:410px">
                                                                    <h5 class="text-center fw-600 mt-3">{{tran.docu_upload}} </h5>
                                                                    <p class="text-center pt-3">{{tran.plea_up}}</p>
                                                                    <div class="display-div">
                                                                        <div>
                                                                            <p class="text-center pt-4">{{tran.id_card}}</p>
                                                                            <picture-input width="250" height="300" margin="6" accept="image/jpeg,image/png,application/pdf" size="2" :crop="false" button-class="btn" :alertOnError="false" :removable="true" ref="id_doc" :custom-strings="{
                                        upload: '<h1>Bummer!</h1>',
                                        tap: 'Tap here to select a photo <br>from your gallery',

                                        drag: 'Click or drag file here',
                                        remove: 'Remove File',
                                        change: 'Change File',
                                        fileSize: 'The file size exceeds t  he limit', }" @change="onChange">
                                                                            </picture-input>
                                                                        </div>
                                                                        <div>
                                                                            <p class="text-center pt-4">{{tran.uti_bill}}</p>
                                                                                <picture-input width="250" height="300" margin="6" accept="image/jpeg,image/png,application/pdf" size="2" :crop="false" button-class="btn" ref="uti_bill" :alertOnError="false" :removable="true" :custom-strings="{
                                                                                upload: '<h1>Bummer!</h1>',
                                                                                tap: 'Tap here to select a photo <br>from your gallery',
                                                                                drag: 'Click or drag file here',
                                                                                remove: 'Remove File',
                                                                                change: 'Change File',
                                                                                fileSize: 'The file size exceeds the limit', }" @change="saveUtiBill">
                                                                                </picture-input>
                                                                        </div>
                                                                    </div>

                                                                    <div class="display-div">
                                                                        <div style="background: #f1faf0 !important; height:50px;border-radius:3px;color:#58B46F" class="position-el  mt-4" v-if="!userDoc" @click="stopEditUserDoc">
                                                                            <p class="text-center"><b>{{tran.cancel}}</b></p>
                                                                        </div>

                                                                        <div style="background: #58B46F !important; height:50px; color:#fff; border-radius:3px" class="position-el mt-4" v-if="!userDoc" @click="updateUserDoc">

                                                                            <p class="text-center" v-if="!userDocLoader"><b>{{userDocLoader ? '' : tran.save}}</b></p>
                                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="userDocLoader" >
                                                                           </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                 -->



                                <div class="modal fade" id="changePinModal" tabindex="-1" role="dialog"
                                    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header"
                                                style="border-bottom:1px solid #F2F2F2 !important;">
                                                <div>

                                                    <h6 class="modal-title head-font" id="exampleModalLongTitle">Change
                                                        PIN
                                                    </h6>
                                                    <p style="color:#828282;; font-size:14px"> {{userProfileInfo.email}}
                                                    </p>
                                                </div>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>

                                            <div class="modal-body security-modal">
                                                <p style="color:#828282;">Enter your current PIN</p>
                                                <div class="eye-div">
                                                    <input v-model="oldPin" :class="{ 'enable-input':true }"
                                                        :type="hideInput" autocomplete="off" maxlength="4">
                                                    <i class="fa fa-eye-slash" @click="showInputText"
                                                        v-if="showEye"></i>
                                                    <i class="fa fa-eye" @click="hideInputText" v-if="hideEye"></i>
                                                </div>
                                                <div style="width:60%; border-top:1px solid #E0E0E0" class="mt-4 mb-4">
                                                </div>
                                                <p style="color:#828282;"> Type a 4 digit PIN</p>
                                                <input :type="hideInput" v-model="newPin" autocomplete="off"
                                                    :class="{ 'enable-input':true }" maxlength="4" placeholder="">
                                                <p style="color:#828282;" class="mt-3">Retype PIN</p>
                                                <input :type="hideInput" maxlength="4" v-model="retypeNewPin"
                                                    autocomplete="off" :class="{ 'enable-input':true }" placeholder="">

                                                <p style="color:#0094FF;" v-if="newPin !== retypeNewPin"
                                                    @click.prevent="changePin"
                                                    class="text-center point-cursor pt-4 pb-4"
                                                    :class="{'fade-elem': oldPin.length < 4}">Save new PIN</p>
                                                <p style="color:#0094FF;" :class="{'fade-elem': oldPin.length < 4 }"
                                                    v-if='!loader && newPin == retypeNewPin' @click.prevent="changePin"
                                                    class="text-center point-cursor pt-4 pb-4">Save new PIN</p>
                                                <div class="text-center pt-4 pb-4" v-if="loader">
                                                    <i class="fa fa-spin fa-spinner" style="color:#0094FF"></i>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="modal fade" id="createPinModal" tabindex="-1" role="dialog"
                                    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header"
                                                style="border-bottom:1px solid #F2F2F2 !important;">
                                                <div>

                                                    <h6 class="modal-title head-font" id="exampleModalLongTitle">Create
                                                        PIN
                                                    </h6>
                                                    <p style="color:#828282;; font-size:14px"> {{userProfileInfo.email}}
                                                    </p>
                                                </div>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>

                                            <div class="modal-body security-modal">
                                                <p style="color:#828282;">Type a 4 digit PIN</p>
                                                <div class="eye-div">
                                                    <input v-model="pin" :class="{ 'enable-input':true }"
                                                        :type="hideInput" id="createPinInput" autocomplete="off"
                                                        maxlength="4">

                                                    <i class="fa fa-eye-slash" @click="showInputText"
                                                        v-if="showEye"></i>
                                                    <i class="fa fa-eye" @click="hideInputText" v-if="hideEye"></i>

                                                </div>

                                                <p style="color:#828282;" class="mt-4">Retype PIN</p>
                                                <input maxlength="4" :type="hideInput" v-model="retypeCreateNewPin"
                                                    autocomplete="off" :class="{ 'enable-input':true }" placeholder="">


                                                <p style="color:#0094FF;" v-if="!loader" @click.prevent="createPin"
                                                    :class="{'fade-elem': pin.length < 4}"
                                                    class="text-center point-cursor pt-4 pb-4">Create PIN</p>
                                                <div class="text-center pt-4 pb-4" v-if="loader">
                                                    <i class="fa fa-spin fa-spinner" style="color:#0094FF"></i>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="modal fade" id="changePassword" tabindex="-1" role="dialog"
                                    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header"
                                                style="border-bottom:1px solid #F2F2F2 !important;">
                                                <div>

                                                    <h6 class="modal-title head-font" id="exampleModalLongTitle">Change
                                                        Password
                                                    </h6>
                                                    <p style="color:#828282;; font-size:14px"> {{userProfileInfo.email}}
                                                    </p>
                                                </div>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>

                                            <div class="modal-body security-modal">
                                                <p style="color:#828282;">Type old Password</p>
                                                <div class="eye-div">
                                                    <input v-model="oldPassword" :class="{ 'enable-input':true }"
                                                        :type="hideInputForPassword" autocomplete="off">
                                                    <i class="fa fa-eye-slash" @click="showInputTextForPas"
                                                        v-if="showEyeForPas"></i>
                                                    <i class="fa fa-eye" @click="hideInputTextForPas"
                                                        v-if="hideEyeForPas"></i>
                                                </div>

                                                <p style="color:#828282;" class="mt-4">New password</p>
                                                <input :type="hideInputForPassword" v-model="newPassword"
                                                    autocomplete="off" :class="{ 'enable-input':true }" placeholder="">
                                                <p style="color:#828282;" class="mt-4">Retype New Password</p>
                                                <input v-model="retypePassword" :type="hideInputForPassword"
                                                    autocomplete="off" :class="{ 'enable-input':true }" placeholder="">


                                                <p style="color:#0094FF;" v-if="newPassword !== retypePassword"
                                                    @click.prevent="changePassword"
                                                    class="text-center point-cursor pt-4 pb-4">Save Password</p>
                                                <p style="color:#0094FF;" @click.prevent="changePassword"
                                                    class="text-center point-cursor pt-4 pb-4"
                                                    v-if='!loader && newPassword == retypePassword'>Save Password</p>
                                                <div class="text-center pt-4 pb-4" v-if="loader">
                                                    <i class="fa fa-spin fa-spinner" style="color:#0094FF"></i>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>






























                                <div class="modal fade" id="referralModal" tabindex="-1" role="dialog"
                                    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header text-right"
                                                style="border-bottom:1px solid #F2F2F2 !important;">

                                                <h4 class="modal-title head-font" id="exampleModalLongTitle">
                                                    {{tran.referrals}}
                                                    <span style="color:#828282;; font-size:16px"> ({{referraLength}})
                                                        -<b><span>&#8358;</span>{{formatAmount(referralTotalAmount)}}</b>
                                                    </span>
                                                </h4>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>

                                            <div class="modal-body">
                                                <div v-if="referraLength < 1">

                                                    <p>Sorry you don't have any Referrals</p>
                                                </div>


                                                <div v-for="ref in userProfileInfo.referral" :key="ref.index"
                                                    style="display:flex; border-bottom:0.5px solid #F2F2F2; justify-content:space-between"
                                                    class="mb-3">
                                                    <div>
                                                        <p class="pb-1"><b> {{ref.first_name}} {{ref.last_name}}</b></p>
                                                        <p style="color:#828282;">{{ref.ref_code}}</p>
                                                    </div>

                                                    <div>
                                                        <p v-if="ref.referral_amount !== null"
                                                            style="min-width:100px; padding:5px" class="table-btn">
                                                            <span>&#8358;</span> {{ formatAmount(ref.referral_amount) }}
                                                        </p>
                                                        <p v-else style="min-width:100px; padding:5px"
                                                            class="fade-ele-btn">
                                                            <span>&#8358;</span>{{formatAmount(0) }}</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="modal fade bd-example-modal-sm" id="verifyPhone"
                                    aria-labelledby="mySmallModalLabel" data-keyboard="false" data-backdrop="static"
                                    aria-hidden="true">
                                    <div class="modal-dialog modal-sm modal-dialog-centered"
                                        style="padding-right:0 !important">
                                        <div class="modal-content" style="border-radius:5px !important;"
                                            v-if="userProfileInfo.phone_verified_at == null && false">
                                            <div class="modal-header text-right" style="border-bottom:1px solid #F2F2F2 !important;    display: flex;
    align-items: center;">

                                                <h6 class="modal-title" id="exampleModalLongTitle"
                                                    style="font-size:13px">
                                                    Enter token sent to <b>{{userProfileInfo.phone}}</b>

                                                </h6>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>

                                            <div class="modal-body">
                                                <div style="display:flex; border-bottom:0.5px solid #F2F2F2;border:1px solid #fff"
                                                    class="mb-3">
                                                    <input type="text" v-model="phoneToken" class="mr-1 form-input"
                                                        id="phoneNumber" v-focus>


                                                </div>
                                            </div>

                                            <div
                                                style="display:flex;background:#fff; justify-content:space-around; padding:20px 0px; border-bottom-left-radius:10px; border-bottom-right-radius:10px; border-top:2px solid #E0E0E0;">
                                                <button data-dismiss="modal" aria-label="Close"
                                                    style="border:1px solid #fff; background:#fff;">cancel</button>
                                                <button
                                                    style="border:1px solid #BDBDBD; background:#fff;border-radius:5px; min-width:100px"
                                                    @click="verifyPhoneToken">

                                                    {{ verifyingToken ? '':'   Send verification code' }}
                                                    <i class="fa fa-spin fa-spinner" v-if="verifyingToken"></i>

                                                </button>
                                            </div>
                                        </div>

                                        <div class="modal-content" style="border-radius:5px !important;">
                                            <div class="modal-header text-right" style="border-bottom:1px solid #F2F2F2 !important;    display: flex;
    align-items: center;">

                                                <h6 class="modal-title" id="exampleModalLongTi tle"
                                                    style="font-size:13px" v-if="numberUnchanged">
                                                    Enter new phone number

                                                </h6>
                                                <h6 class="modal-title" id="exampleModalLongTitle"
                                                    style="font-size:13px" v-if="!numberUnchanged">
                                                    Enter token sent to <b>{{userProfileInfo.phone}}</b>

                                                </h6>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>

                                            <div class="modal-body">
                                                <div style="display:flex; border-bottom:0.5px solid #F2F2F2;border:1px solid #fff"
                                                    class="mb-3">
                                                    <input type="text" v-model="phoneToken" class="mr-1 form-input"
                                                        id="phoneNumber" v-if="!numberUnchanged">
                                                    <input type="text" v-model="userProfileInfo.phone"
                                                        class="mr-1 form-input" id="phoneNumber" v-if="numberUnchanged">


                                                </div>
                                            </div>

                                            <div
                                                style="display:flex;background:#fff; justify-content:space-around; padding:20px 0px; border-bottom-left-radius:10px; border-bottom-right-radius:10px; border-top:2px solid #E0E0E0;">

                                                <button data-dismiss="modal" aria-label="Close"
                                                    style="border:1px solid #fff; background:#fff;"
                                                    v-if="numberUnchanged">cancel</button>



                                                <p @click="userWantsToEditPhone" class="point-cursor"
                                                    style="border:1px solid #fff; background:#fff; font-size:13px"
                                                    v-if="!numberUnchanged">
                                                    Edit phone number

                                                </p>


                                                <button
                                                    style="border:1px solid #BDBDBD; background:#fff;border-radius:5px; min-width:100px"
                                                    @click="ChangePhoneNumber" v-if="numberUnchanged">

                                                    {{ verifyingPhone ? '':'   Send verification code' }}
                                                    <i class="fa fa-spin fa-spinner" v-if="verifyingPhone"></i>

                                                </button>
                                                <button
                                                    style="border:1px solid #BDBDBD; background:#fff;border-radius:5px; min-width:100px"
                                                    @click="verifyPhoneToken" v-if="!numberUnchanged">

                                                    {{ verifyingToken ? '':'   Verify' }}
                                                    <i class="fa fa-spin fa-spinner" v-if="verifyingToken"></i>

                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <!-- Forgot Pin Modal  -->

    <div class="modal fade" id="forgotPin" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                                                                <div class="modal-dialog modal-dialog-centered" role="document">
                                                                                    <div class="modal-content">
                                                                                        <div class="modal-header" style="border-bottom:1px solid #F2F2F2 !important;">
                                                                                            <div>

                                                                                            <h6 class="modal-title head-font" id="exampleModalLongTitle">Forgot Pin
                                                                                       </h6>
                                                                                           
                                                                                            </div>
                                                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                                              <span aria-hidden="true">&times;</span>
                                                                                             </button>
                                                                                        </div>
                                                                                   
                                                                                        
                                                                                        <div class="modal-body security-modal"  style=" padding: 20px 32px !important;">
                                                                                             <p style="color:#828282;" class="pb-3">Your PIN is critical to protecting your reQuid account, if you have forgotten it, you can request for a reset</p>
                                                                                                <p style="color:#828282;" class="pb-3">If you request a reset, you will receive instructions via email, but as an extra layer of protection, you can only take action after{{userProfileInfo.pin_reset_wait_time}} hours</p>
                                                                                                 <p style="color:#828282;" class="pb-3"><b>Note:</b> you will need access to your email and registered phone number.</p>
                                                                                        <p style="color:#828282;" class="pb-3"><b> Confirm your reQuid password : </b></p>
                                                                                                <div class="eye-div">
                                <input
                    v-model="oldPassword"
                    :class="{ 'enable-input':true }"
                    :type="hideInputForPassword"
                       readonly onfocus="this.removeAttribute('readonly');"
                    autocomplete="off" style="text-align:left">
                                <i class="fa fa-eye-slash" @click="showInputTextForPas" v-if="showEyeForPas" style="top: 15px;"></i>
                                <i class="fa fa-eye" @click="hideInputTextForPas" v-if="hideEyeForPas" style="top: 15px;"></i>
                            </div>
                                                                                             
                                                                                              
                                                                                       
                                                                                         <p style="color:#0094FF;" @click.prevent="forgotPin" class="text-center point-cursor pt-4 pb-4" v-if='!loader '>Continue </p>
                                                                                         <div class="text-center pt-4 pb-4" v-if="loader">
                                                                                                    <i class="fa fa-spin fa-spinner" style="color:#0094FF"></i>
                                                                                                     
                                                                                         </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                <!-- Testimonial Modal  -->

                                <div class="modal fade" id="exampleModal2" role="dialog"
                                    aria-labelledby="exampleModalLabel" aria-hidden="true" v-if="showModal">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel"> </h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>

                                            </div>
                                            <!-- <div class="modal-header" v-if="testimonialData.testimony !== ''">
          
                    <h5 class="modal-title" id="exampleModalLabel"> </h5>
                    <button type="button" class="close" @click="userStillTyping = false"  data-target="#confirmModal" data-toggle="modal">
                            <span aria-hidden="true"> &times; </span>
                    </button>
                  
                </div> -->


                                            <div class="modal-body">
                                                <div v-show="fillTestimony">
                                                    <h4 class="head-font  text-center mb-2">{{tran.rate_us}}</h4>

                                                    <p class="text-center">{{tran.let_us_know}}</p>

                                                    <div class="text-center pt-4"
                                                        style="display:flex; justify-content:center; flex-direction:column">

                                                        <center>
                                                            <div style="width:40%; border-top: 1px solid #FDC23C;">
                                                            </div>
                                                        </center>
                                                        <p class="pt-2"><b>{{tran.rate_your_expe}}</b></p>
                                                    </div>

                                                    <!-- <div class="text-center rate-div">
                        <fieldset class="rating text-center">
                            <input type="radio" id="star51" name="rating" value="5" :checked="getStarRate() == 5"/>
                            <label class="full" for="star51"  title="Awesome - 5 stars" @click="highStarMethod(5)"></label>

                            <input type="radio" id="star41" name="rating" value="4" :checked="getStarRate() == 4" />
                            <label class = "full" for="star41" @click="highStarMethod(4)"></label>

                            <input type="radio" id="star31" name="rating" value="3" :checked="getStarRate() == 3" /><label class = "full" for="star31" @click="highStarMethod(3)"></label>

                            <input type="radio" id="star21" name="rating" value="2" :checked="getStarRate() == 2" />
                            <label class="full" for="star21" @click="lowStarMethod(2)"></label>

                            <input type="radio" id="star11" name="rating" value="1"  :checked="getStarRate() == 1"/><label class = "full" for="star11"  @click="lowStarMethod(1)" ></label>
                        </fieldset>
                    </div> -->

                                                    <div class="text-center pt-1"
                                                        style="display:flex; justify-content:center; flex-direction:column">

                                                        <center>
                                                            <div style="width:60%; border-top: 1px solid #FDC23C;">
                                                            </div>
                                                            <div style="width:20%; border-top: 1px solid #FDC23C;"
                                                                class="mt-3"></div>
                                                        </center>

                                                    </div>

                                                    <div class="mt-3 mb-3">
                                                        <p class="text-center animated fadeIn" v-if="highStar">
                                                            <b>Nice!</b> {{tran.nice_do_you}} </p>

                                                        <p class="text-center animated fadeIn" v-if="lowStar">
                                                            <b>Ouch!</b> {{tran.ouch_please}} </p>
                                                    </div>

                                                    <div class="mt-1">
                                                        <textarea name="" id="" cols="30"
                                                            v-model="testimonialDataFromStore.testimony" rows="4"
                                                            style="width:100%; border-radius:5px !important"
                                                            minlength="3" maxlength="250"
                                                            placeholder="Type here"></textarea>
                                                    </div>
                                                    <div class="com-pub mt-2">
                                                        <div
                                                            class="custom-control text-center custom-checkbox my-1 mt-4 mr-sm-2">
                                                            <input type="checkbox" class="custom-control-input"
                                                                v-model="testimonialData.share"
                                                                id="customControlInline">
                                                            <label class="custom-control-label"
                                                                title="Make my comments public"
                                                                for="customControlInline"><b>Make my comments
                                                                    public</b></label>

                                                        </div>

                                                        <p class="text-center pt-2">Your testimonial helps inform others
                                                            to invest healthy.</p>
                                                    </div>

                                                    <p class="drift-open-chat text-center pt-3"
                                                        style="text-decoration:underline; cursor:pointer">
                                                        {{ tran.contact_sup }} </p>
                                                </div>


                                                <template v-if="!fillTestimony">
                                                    <img src="../../assets/images/back.png" alt="" height="30" style="cursor:pointer;cursor: pointer;
    position: absolute;
    top: -38px;" @click="goBackToTestimonial">
                                                    <h6 class="text-center pt-2 pb-2"> <b> Share your experience on:
                                                        </b> </h6>

                                                    <center>
                                                        <div style="width:40%; border-top: 1px solid #FDC23C;"
                                                            class="mb-3">
                                                        </div>
                                                    </center>
                                                    <div class="position-el text-center" style="font-size:25px">
                                                        <social-sharing
                                                            :url="`https://market.requid.com/signup?ref=${userProfileInfo.ref_code}`"
                                                            :description="testimonialData.testimony"
                                                            :title="testimonialData.testimony"
                                                            :quote="testimonialData.testimony"
                                                            hashtag="#requid, #invest" twitter-user="requidapp"
                                                            inline-template>

                                                            <div>
                                                                <network network="twitter">
                                                                    <img src="../../assets/images/twitter.png" alt=""
                                                                        title="share via twitter"
                                                                        v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'
                                                                        style="margin-right: 40px !important;"
                                                                        height="38">
                                                                    <!-- <i class="fa fa-twitter"  ></i> -->
                                                                </network>

                                                                <!-- <network network="linkedin">
                                                        <i class="fa fa-linkedin" title="share via linkedin" v-tippy='{ arrow : true, placement:"top",arrowType : "round"}' style="margin-right: 20px !important;
                                                            padding-left: 10px;
                                                            padding-right: 10px;
                                                            border-radius: 5px;"></i>
                                                    </network> -->


                                                                <network network="facebook">
                                                                    <img src="../../assets/images/face.png" alt=""
                                                                        title="share via facebook"
                                                                        v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'
                                                                        style="margin-right: 40px !important;"
                                                                        height="38">
                                                                </network>



                                                                <network network="whatsapp">
                                                                    <img src="../../assets/images/what.png" alt=""
                                                                        title="share via whatsApp"
                                                                        v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'
                                                                        height="38">
                                                                </network>
                                                            </div>
                                                        </social-sharing>
                                                    </div>
                                                    <center>
                                                        <div style="width:60%; border-top: 1px solid #FDC23C;"
                                                            class="mt-3"></div>
                                                        <div style="width:20%; border-top: 1px solid #FDC23C;"
                                                            class="mt-3"></div>
                                                    </center>
                                                </template>
                                            </div>

                                            <div class="modal-footer" v-show="shareNext" @click="shareOnSocialMedia"
                                                :class="{'fade-elem':testimonialDataFromStore.rating == '' || testimonialDataFromStore.testimony == '' }">
                                                <p> {{ tran.next }} </p>
                                            </div>

                                            <div class="modal-footer" @click="submitTestimonial"
                                                v-show="submitTestimony"
                                                :class="{'fade-elem':testimonialDataFromStore.rating == '' || testimonialDataFromStore.testimony == '' }">
                                                <p>{{ loader ? '' : tran.submit}}
                                                    <span class="loader" v-if="loader" style="top:94%"></span>
                                                </p>
                                            </div>

                                            <div class="modal-footer" @click="closeTestimonial" v-show="!fillTestimony">
                                                <p>{{ tran.done}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </template>
                    <template v-if="searchString !== ''">
                        <searchProduct />
                    </template>
                </div>
            </div>
        </Wrapper>

    </div>
</template>

<script>
    import Navbar from '../partials/Navbar'
    import Sidebar from '../partials/Sidebar'
    import Footer from '../partials/Footer'
    import Wrapper from '../partials/Wrapper'
    import trans from "../../../translations";
    import searchProduct from "./SearchProduct";
    import http from "../../http/index";
    import api from "../../apis/index";
    import {
        commonJs
    } from './mixins/commonJs.js';
    import {
        profileMixin
    } from './mixins/profileMixin.js';

    import {
        mapState
    } from "vuex";
    import PictureInput from 'vue-picture-input'
    import { setTimeout } from 'timers';

    export default {
        mixins: [commonJs, profileMixin],
        data() {

            return {
            }
        },


        components: {
            Navbar,
            Footer,
            Sidebar,
            Wrapper,
            PictureInput,
            searchProduct
        },

        mounted() {

            if (this.$route.query.openCreatePin) {

                $('#createPinModal').modal('show');

                $('#createPinInput').focus();
            }
        }

    }
</script>

<style lang="scss" scoped>
    .no-border-phone {
        border: 1px solid transparent !important;
    }

    @media(max-width:399px) {
        .mob-div {
            div {

                flex-basis: 100%;
                text-align: left !important;
            }
        }
    }

    .mob {
        border-bottom: 1px solid #f2f2f2;
        padding-bottom: 10px;
    }

    .mob-img {
        height: 100 !important;
    }

    .nav-tabs {
        max-width: 50%;
        //   margin: 0 auto;
    }

    .project-tab {
        padding: 10% 8%;
        margin-top: -8%;

        @media(max-width: 1110px) {


            padding: 10% 2% !important;

        }
    }

    .nav-tabs {
        border-bottom: 1px solid #fff;
    }


    .project-tab #tabs .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        color: #4F4F4F;
        background-color: transparent;
        border-color: transparent transparent transparent;
        border-bottom: 2px solid #0094ff !important;
        font-size: 16px;
    }

    .project-tab .nav-link {
        border: 1px solid transparent;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        color: #4F4F4F;
        font-size: 16px;

    }

    .project-tab thead {
        background: #f3f3f3;
        color: #333;
    }

    .project-tab a {
        text-decoration: none;
    }

    .tab-div-2 {
        align-items: center;
        background: #fff;
        border-radius: 10px;
        padding: 20px;
    }

    .disable-input {

        pointer-events: none !important;
        background: #FAFAFA;
        border: 0px solid;
        border-radius: 5px;
        padding: 5px;
        min-height: 45px;
        text-align: center;
        color: #000;


    }

    .enable-input {

        background: #fff;
        border: 1px solid #BDBDBD;
        border-radius: 5px;
        padding: 5px;
        min-height: 45px;
        text-align: center;
        color: #000;

    }

    .enable-input:focus {
        border: 1px solid #0094FF;
    }

    .security-modal {

        padding: 20px 100px !important;

        input {

            min-width: 100%;
        }

        @media(max-width:499px) {
            padding: 20px 50px !important;
        }

        .eye-div i {
            position: absolute;
            left: 91% !important;
            top: 10px;
            cursor: pointer;
        }
    }

    @media(max-width:110px) {
        .project-tab {

            padding: 10% 4% !important;
        }
    }
</style>